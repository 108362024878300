import React from 'react'

function NavLogo() {
    return (
        <svg className='nav-logo' width="111" height="32" viewBox="0 0 111 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_109_1755)">
                <path d="M9.23145 21.0875V19.0229H-0.000236511V21.0875H9.23145Z" fill="white"/>
                <path d="M22.5664 21.0875V19.0229H13.3347V21.0875H22.5664Z" fill="white"/>
                <path d="M10.2571 17.9907H12.3086V8.7004H10.2571V17.9907Z" fill="white"/>
                <path d="M10.2571 31.4102H12.3086V22.1198H10.2571V31.4102Z" fill="white"/>
                <path d="M23.7387 13.272H25.4971V8.70055H23.7387V13.272Z" fill="white"/>
                <path d="M23.7387 4.57129H25.4971V-0.00013876H23.7387V4.57129Z" fill="white"/>
                <path d="M18.0234 5.75092V7.52051H22.566V5.75092H18.0234Z" fill="white"/>
                <path d="M26.6689 5.75092V7.52051H31.2115V5.75092H26.6689Z" fill="white"/>
                <path d="M52.6018 23.5397H41.4242L38.7814 31.41H35.4961L44.3167 5.75098H49.7444L58.6009 31.41H55.2438L52.6018 23.5397ZM42.3884 20.5904H51.6017L47.709 9.05715H46.2803L42.3884 20.5904Z" fill="white"/>
                <path d="M64.7423 5.75098V15.2743H65.4566C66.4567 13.9803 68.0994 12.8293 70.9223 12.8293C75.9221 12.8293 78.9217 16.9259 78.9217 22.3526C78.9217 27.7432 75.9221 31.876 70.9223 31.876C68.1016 31.876 66.3512 30.6896 65.354 29.4325H64.6397V31.41H61.709V5.75098H64.7423ZM75.884 22.3541C75.884 18.329 73.9915 15.4896 70.3487 15.4896C66.349 15.4896 64.7071 18.7958 64.7071 22.3541C64.7071 25.8756 66.3497 29.2179 70.3487 29.2179C73.9915 29.2179 75.884 26.3792 75.884 22.3541Z" fill="white"/>
                <path d="M93.2388 16.1727H91.8467C88.3116 16.1727 86.1692 18.4009 86.1692 22.3544V31.4103H83.1338V13.2979H86.0645V15.4899H86.7788C87.672 14.3758 89.3857 13.2979 91.9925 13.2979H93.2425L93.2388 16.1727Z" fill="white"/>
                <path d="M108.131 29.4696H107.417C106.309 30.8705 104.345 31.8777 101.775 31.8777C97.5987 31.8777 95.2754 29.0758 95.2754 25.9835C95.2754 23.0733 97.1327 19.9824 102.168 19.9824H108.024V19.2635C108.024 17.0714 106.739 15.4899 103.632 15.4899C100.99 15.4899 99.5967 16.5324 99.4553 18.1133H96.4198C96.6338 14.7717 99.5264 12.8311 103.562 12.8311C108.061 12.8311 110.99 15.0231 110.99 19.0836V31.4102H108.132L108.131 29.4696ZM108.023 24.1512V22.5335H102.631C99.7007 22.5335 98.3087 23.7914 98.3087 25.912C98.3087 27.9603 99.8473 29.2181 102.485 29.2181C105.524 29.2181 108.023 26.9899 108.023 24.1512Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_109_1755">
                    <rect width="111" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default NavLogo
