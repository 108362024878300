import React from 'react'

function SuccessIcon() {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M67.1875 40.625L44.2707 62.5L32.8125 51.5625" stroke="#79D277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M50 87.5C70.7107 87.5 87.5 70.7107 87.5 50C87.5 29.2893 70.7107 12.5 50 12.5C29.2893 12.5 12.5 29.2893 12.5 50C12.5 70.7107 29.2893 87.5 50 87.5Z" stroke="#79D277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default SuccessIcon
