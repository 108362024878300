import React from 'react'

function FrontImage() {
    return (
        <svg className='front-image' width="252" height="252" viewBox="0 0 252 252" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M75 170L75 153L0 153L7.43093e-07 170L75 170Z" fill="#FFA060"/>
            <path d="M181 170L181 153L106 153L106 170L181 170Z" fill="#FFA060"/>
            <path d="M82 146L99 146L99 71L82 71L82 146Z" fill="#FFA060"/>
            <path d="M82 252L99 252L99 177L82 177L82 252Z" fill="#FFA060"/>
            <path d="M192 106L206 106L206 67L192 67L192 106Z" fill="#FFA060"/>
            <path d="M192 39L206 39L206 0L192 4.44146e-07L192 39Z" fill="#FFA060"/>
            <path d="M146 46L146 60L185 60L185 46L146 46Z" fill="#FFA060"/>
            <path d="M213 46L213 60L252 60L252 46L213 46Z" fill="#FFA060"/>
        </svg>
    )
}

export default FrontImage
