import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { tryChangePassword } from '../../store/asyncActons';
import { changePasswordValidator } from '../../utils/validators';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { onResetChangePasswordForm } from '../../store/changePasswordReducer';
import SubmitButton from './submit-button';

function ChangePasswordForm() {

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false)

    const {success, loading, error} = useSelector(state => state.changePassword)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(onResetChangePasswordForm())
      
      }, [])

    const formik = useFormik({
        initialValues: {
            oldPassword:'',
            newPassword: '',
            confirmPassword: '',
        },
        validate:(values) => changePasswordValidator(values),
        onSubmit: async (values, { setSubmitting }) => {
            console.log(values);    
            dispatch(tryChangePassword(values.oldPassword, values.newPassword, (data)=>{
                console.log(data)
            }))
        },
    });

    return (
        <div className='form-container'>
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <Collapse in={success || error !== null}>
                        <Alert
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(onResetChangePasswordForm());
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        severity={success ? 'success' : 'error'}
                        >
                            {success && 'The password has been successfully changed.'}
                            {error && error.message}
                        
                        </Alert>
                    </Collapse>

                    <FormControl 
                        sx={{ marginTop: '10px', width: '100%' }}
                        variant="standard"
                        error={formik.errors.oldPassword && formik.touched.oldPassword}>
                        <InputLabel htmlFor="oldPassword">Old password</InputLabel>
                        <Input
                            id="oldPassword"
                            type={showOldPassword ? 'text' : 'password'}
                            value={formik.values.oldPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowOldPassword(!showOldPassword)}
                                >
                                {showOldPassword ? <VisibilityOff /> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        {formik.errors.oldPassword && formik.touched.oldPassword && <FormHelperText>{formik.errors.oldPassword}</FormHelperText>}
                    </FormControl>
                </div>
                <div>
                    <FormControl 
                        sx={{ marginTop: '10px', width: '100%' }}
                        variant="standard"
                        error={formik.errors.newPassword && formik.touched.newPassword}>
                        <InputLabel htmlFor="newPassword">New password</InputLabel>
                        <Input
                            id="newPassword"
                            type={showNewPassword ? 'text' : 'password'}
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                >
                                {showNewPassword ? <VisibilityOff /> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        {formik.errors.newPassword && formik.touched.newPassword && <FormHelperText>{formik.errors.newPassword}</FormHelperText>}
                    </FormControl>
                </div>
                <div>
                    <FormControl 
                        sx={{ marginTop: '10px', width: '100%' }}
                        variant="standard"
                        error={formik.errors.confirmPassword && formik.touched.confirmPassword}>
                        <InputLabel htmlFor="confirmPassword">Repeat new password</InputLabel>
                        <Input
                            id="confirmPassword"
                            type={showNewPasswordConfirm ? 'text' : 'password'}
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowNewPasswordConfirm(!showNewPasswordConfirm)}
                                >
                                {showNewPasswordConfirm ? <VisibilityOff /> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        {formik.errors.confirmPassword && formik.touched.confirmPassword && <FormHelperText>{formik.errors.confirmPassword}</FormHelperText>}
                    </FormControl>
                </div>
                {/* <div className='form-error'>{error && error.message}</div> */}
                <div>
                    {/* <button type='submit' className={`submit-btn ${!(formik.isValid && formik.dirty) ? 'btn-disabled' : ''}`}>
                        Change password
                    </button> */}
                    <SubmitButton label="Change password" disabled={!(formik.isValid && formik.dirty)} loading={loading}/>
                </div>
            </form>
        </div>
    )
}

export default ChangePasswordForm
