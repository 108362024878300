const defaultState = {
    isAuth: false,
    userAttributes: {},
    onLoginLoading: false,
    error: null,
}

const ON_RESET_AUTH_FORM = 'ON_RESET_AUTH_FORM';

const ON_USER_AUTHORIZE_REQUEST = 'ON_USER_AUTHORIZE_REQUEST';
const ON_USER_AUTHORIZED = 'ON_USER_AUTHORIZED';
const ON_USER_AUTHORIZE_FAILURE = 'ON_USER_AUTHORIZE_FAILURE';
const ON_USER_LOG_OUT = 'On_USER_LOG_OUT';

export const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ON_RESET_AUTH_FORM:
            return {...state, ...defaultState}

        case ON_USER_AUTHORIZE_REQUEST:
            return {...state, isAuth: false, onLoginLoading: true, error: null, userAttributes: {}}
        case ON_USER_AUTHORIZED:
            return {...state, isAuth: true, onLoginLoading: false, error: null, userAttributes: {...action.payload}}
        case ON_USER_AUTHORIZE_FAILURE:
            return {...state, isAuth: false, onLoginLoading: false, error: action.payload, userAttributes: {}}

        case ON_USER_LOG_OUT:
            return {...state, isAuth: false, userAttributes: {}}

        default:
            return state
    }
}

export const onResetAuthForm= (payload) => ({ type: ON_RESET_AUTH_FORM, payload });

export const onUserAuthorizeRequest = (payload) => ({type:ON_USER_AUTHORIZE_REQUEST, payload})
export const onUserAuthorized = (payload) => ({type:ON_USER_AUTHORIZED, payload})
export const onUserAuthorizeFailure = (payload) => ({type:ON_USER_AUTHORIZE_FAILURE, payload})
export const onUserLogOut = () => ({type:ON_USER_LOG_OUT})