import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    components: {
        MuiFormControl:{
            styleOverrides:{
                root: {
                    // Some CSS
                    width: '100%',
                    height: '65px'
                },
            }
        },
        MuiInputBase:{
            styleOverrides:{
                root:{
                    color: '#001F40',
                },
            }
        },
        MuiInput:{
            styleOverrides:{
                root:{
                    color: '#001F40',
                    marginTop: '10px !important',
                },
                underline: {
                    '&:before': {
                        borderBottom: '1px solid #e5e9ec'
                    },
                    '&:after': {
                        borderBottom: `2px solid #001F40`
                    },
                    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
                        borderBottom: `2px solid #001F40`
                    }
                }
            }
        },
        MuiInputLabel:{
            styleOverrides:{
                root:{
                    color: '#808F9F',
                    top: '-4px',
                    "&.Mui-focused": {
                        color: "#001F40"
                      }
                },
            }
        },
        MuiFormHelperText: {
            styleOverrides:{
                root:{
                    marginTop: '0px'
                },
            }
        }

    }
});