import './App.css';
import config from '../../config/config';
import Amplify from 'aws-amplify';
import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from '../app-router/app-router';
import { useDispatch, useSelector } from 'react-redux';
import { checkUser } from '../../store/asyncActons';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    // REQUIRED - Amazon Cognito Identity Pool ID
    userPoolId: config.userPoolId,
    // REQUIRED - Amazon Cognito Region
    region: config.region,
    // OPTIONAL - Amazon Cognito User Pool ID
    identityPoolId: config.identityPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: config.userPoolWebClientId,
  },
  aws_appsync_graphqlEndpoint: config.graphqlEndpoint,
  aws_appsync_region: config.appsyncRegion,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

function App() {

  const dispatch = useDispatch()
  const appIsLoading = useSelector(state=>state.app.isLoading)


  useEffect(() => {

    console.log('first use effect')
    dispatch(checkUser())

  }, [])

  if(appIsLoading){
    return (
      <div>
        Loading...
      </div>
    )
  }

  return (
    <div className="App">
      <BrowserRouter>
        <AppRouter/>
      </BrowserRouter>
    </div>
  );
}

export default App;
