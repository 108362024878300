import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInfo } from '../../store/asyncActons'
import AddCustomerForm from '../forms/add-customer-form'
import LanguageSelector from '../language-selector'
import Navbar from '../navbar'
import FrontImage from './../icons/front-image';
import i18next from "i18next";
import Amplify from 'aws-amplify';
import config from '../../config/config';
import SuccessIcon from '../icons/success-icon'
import SubmitButton from '../forms/submit-button'
import { onResetAddCustomerForm } from '../../store/addCustomerReducer'

function AddCustomerPage() {

    Amplify.configure({
        Auth: {
            mandatorySignIn: false,
            // REQUIRED - Amazon Cognito Identity Pool ID
            userPoolId: config.userPoolId,
            // REQUIRED - Amazon Cognito Region
            region: config.region,
            // OPTIONAL - Amazon Cognito User Pool ID
            identityPoolId: config.identityPoolId,
            // OPTIONAL - Amazon Cognito Web Client ID
            userPoolWebClientId: config.userPoolWebClientId,
        },
        aws_appsync_graphqlEndpoint: config.graphqlEndpoint,
        aws_appsync_region: config.appsyncRegion,
        aws_appsync_authenticationType: "AWS_IAM"
        });

    const {success, loading, error} = useSelector(state => state.addCustomer)
    const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(fetchUserInfo())
    // }, [])

    return (
        <div className='abra-page invite-user-page'>
            <section className='page-part page-filler'>
                <FrontImage/>
            </section>
            <section className='page-part page-main'>
                <div className='page-content'>
                    <div className='language-selector-container'>
                        <LanguageSelector/>
                    </div>
                    <div className='content-card'>
                        
                        <div className='portal-desc' style={{fontSize: '2.4rem', marginBottom: '70px'}}>
                            {i18next.t('pages.addCustomer.portalDescription')}
                        </div>
                        {!success ? <>
                            <h1>{i18next.t('pages.addCustomer.title')}</h1>
                            <AddCustomerForm/>
                            </> : <AddCustomerSuccess/>
                        }
                        
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AddCustomerPage


function AddCustomerSuccess() {
    const dispatch = useDispatch()
    
    return (
        <div className='add-customer-success'>
            <div style={{textAlign:'center'}}>
                <SuccessIcon/>
            </div>
            
            <div style={{fontSize: '1.6rem', marginTop: '50px', marginBottom: '70px', textAlign: 'center'}}>
                {i18next.t('forms.addCustomer.successMessageText')}
            </div>
            <SubmitButton label={i18next.t('forms.addCustomer.addNewUserButtonLabel')} onClick={() => dispatch(onResetAddCustomerForm())} disabled={false} loading={false}/>
        </div>
    )
}


