import { Auth, API, graphqlOperation } from 'aws-amplify';
import { acceptInvitation, addCustomer } from '../graphql/mutations';
import { getUserInformation } from '../graphql/queries';
import { USER_INFO_ROUTE } from '../utils/constants';
import { onAddCustomerFailure, onAddCustomerRequest, onCustomerAdded } from './addCustomerReducer';
import { setAppIsLoading } from './appReducer';
import { onUserAuthorized, onUserAuthorizeFailure, onUserAuthorizeRequest } from './authReducer';
import { onUserChangePasswordFailure, onUserChangePasswordRequest, onUserPasswordChanged } from './changePasswordReducer';
import {
    onSendCodeFailure,
    onSendCodeRequest,
    onSendCodeSuccess,
    onResetPasswordRequest,
    onResetPasswordSuccess,
    onResetPasswordFailure 
} from './resetPasswordReducer';

import { fetchUserFailure, fetchUserLoaded, fetchUserRequest } from './userReducer';
import { onAcceptInvitationFailure, onAcceptInvitationRequest, onInvitationAccepted } from './acceptInvitationReducer';
import { getPhoneWithFormat } from '../utils/functions';
import { datadogLogs } from '@datadog/browser-logs';

export const checkUser = () => {
    
    return function(dispatch){
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
          console.log('try auth user', user);
          dispatch(onUserAuthorized(user.attributes))
        })
        .catch(err => console.log('no auth user: ',err))
        .finally(() => dispatch(setAppIsLoading(false)));
    }
}

export const fetchUserInfo = (id) => {
    
    return function(dispatch){

        dispatch(fetchUserRequest())

        API.graphql(graphqlOperation(getUserInformation))
            .then(response => {
                console.log('response',response)
                if(response.data.errors){
                    const error = response.data.errors[0];
                    dispatch(fetchUserFailure(error))
                 }
                 else{
                     dispatch(fetchUserLoaded(response.data.UserGetUser))
                 }
            })
            .catch(err => {
                console.log('error:', err)
                dispatch(fetchUserFailure(err))
            });
    }
}

export const tryUserLogin = (email, password, navigate) => {
    
    return function(dispatch){

        dispatch(onUserAuthorizeRequest())

        Auth.signIn(email, password)
            .then(user => {
                dispatch(onUserAuthorized(user.attributes))
                navigate(USER_INFO_ROUTE)
            })
            .catch(err => {
                console.log('error:', err)
                dispatch(onUserAuthorizeFailure(err))
            })
    }
}

export const trySendResetCode = (email) => {
    
    return function(dispatch){

        dispatch(onSendCodeRequest())

        Auth.forgotPassword(email)
            .then(data => {
                dispatch(onSendCodeSuccess(data))
            })
            .catch(err => {
                console.log('error:', err)
                dispatch(onSendCodeFailure(err))
            })
    }
}

export const tryResetPassword = (email, code, newPassword) => {
    
    return function(dispatch){

        dispatch(onResetPasswordRequest())

        Auth.forgotPasswordSubmit(email, code, newPassword)
            .then(data => {
                dispatch(onResetPasswordSuccess(data))
            })
            .catch(err => {
                console.log('error:', err)
                dispatch(onResetPasswordFailure(err))
            })
    }
}

export const tryChangePassword = (oldPassword, newPassword, callback) => {
    
    return function(dispatch){

        dispatch(onUserChangePasswordRequest())

        Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then(data => {
            dispatch(onUserPasswordChanged())
            if(callback){
                callback(data)
            }
        })
        .catch(err => {
            console.log('error:', err)
            dispatch(onUserChangePasswordFailure(err))
        })

    }
}

// export const tryAddCustomer = (newCustomerData) => {
    
//     return function(dispatch){

//         dispatch((onAddCustomerRequest()))

//         API.graphql(graphqlOperation(AdminRegisterAccount, {details: newCustomerData}))
//             .then(response => {
//                 console.log('response',response)
//                 if(response.data.errors){
//                     const error = response.data.errors[0];
//                     dispatch(onAddCustomerFailure(error))
//                  }
//                  else{
//                      dispatch(onCustomerAdded(response.data))
//                  }
//             })
//             .catch(err => {
//                 console.log('error:', err)
//                 dispatch(onAddCustomerFailure(err))
//             });
//     }
// }

export const tryAddCustomer = (campaignId, newCustomerData) => {
    
    return async function(dispatch){

        dispatch((onAddCustomerRequest()))

        try{
            const [countryCode, nationalNumber] = getPhoneWithFormat(newCustomerData.phone);

            const response = await API.graphql(graphqlOperation(addCustomer, {campaignId: campaignId, data: {
                email: newCustomerData.email,
                firstName: newCustomerData.firstName,
                lastName: newCustomerData.lastName,
                address: newCustomerData.address,
                city: newCustomerData.city,
                postalCode: newCustomerData.postalCode,
                region: 'reg-1',
                phoneNumber: {countryCode, nationalNumber}
            }}))
            console.log('response',response)
            if(response.data.userInvite.errors && response.data.userInvite.errors.length > 0){
                console.log('error array')
                const error = response.data.userInvite.errors[0];
                datadogLogs.logger.error(error, {action: 'add-customer', path: 'invite-user'})
                dispatch(onAddCustomerFailure(error))
            }
            else{
                dispatch(onCustomerAdded(response.data.userInvite))
            }
        }
        catch(err){
            console.log('error:', err)
            datadogLogs.logger.error(err, {action: 'add-customer', path: 'invite-user'})
            dispatch(onAddCustomerFailure(err))
        }
    }
}

export const tryAcceptInvitation = (invitationId, token) => {
    
    return async function(dispatch){

        dispatch((onAcceptInvitationRequest()))

        try{
            const response = await API.graphql(graphqlOperation(acceptInvitation, {invitationId, token}))
            console.log('response',response)
                if(response.data.userAcceptInvitation.errors && response.data.userAcceptInvitation.errors.length > 0){
                    const error = response.data.userAcceptInvitation.errors[0];
                    datadogLogs.logger.error(error, {action: 'accept-invitation', path: 'accept-terms'})
                    dispatch(onAcceptInvitationFailure(error))
                 }
                 else{
                     dispatch(onInvitationAccepted(response.data.userAcceptInvitation))
                 }
        }
        catch(err){
            console.log('error:', err)
            datadogLogs.logger.error(err, {action: 'accept-invitation', path: 'accept-terms'})
            dispatch(onAcceptInvitationFailure(err))
        }
    }
}