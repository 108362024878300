const defaultState = {
    isUserLoading: false,
    userData: {},
    error: null,
}

const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
const FETCH_USER_LOADED = 'FETCH_USER_LOADED';
const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const userReducer = (state = defaultState, action) => {
    switch (action.type) {

        case FETCH_USER_REQUEST:
            return {...state, userData: {}, isUserLoading: true, error: null}
        case FETCH_USER_LOADED:
            return {...state, userData: action.payload, isUserLoading: false, error: null}
        case FETCH_USER_FAILURE:
            return {...state, userData:{}, isUserLoading: false, error: action.payload}
        default:
            return state
    }
}

export const fetchUserRequest = (payload) => ({ type: FETCH_USER_REQUEST, payload });
export const fetchUserLoaded = (payload) => ({ type: FETCH_USER_LOADED, payload });
export const fetchUserFailure = (payload) => ({ type: FETCH_USER_FAILURE, payload });