import React from 'react'
import { Auth } from 'aws-amplify';
import {useDispatch, useSelector} from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { onUserLogOut } from '../../store/authReducer';
import { CHANGE_PASSWORD_ROUTE, SIGNIN_ROUTE } from '../../utils/constants';
import InfoItem from './info-item';
import SignOutIcon from '../icons/sign-out-icon';

import './user-info.css';

function UserInfo() {

    const userData = useSelector(state=>state.user.userData)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const signOut = async() => {
        
        try {
            await Auth.signOut();
            dispatch(onUserLogOut())
            navigate(SIGNIN_ROUTE)
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <div className='user-info'>
            <div className='content-card user-card'>
                <div className='info-item-list'>
                    <InfoItem label="User Name" value={`${userData.first_name || '-'} ${userData.last_name || '-'}`}/>
                    <InfoItem label="E-mail" value={userData.email}/>
                    <InfoItem label="Phone" value={userData.phone}/>
                </div>
                <div className='cg-psw-container'>
                    <NavLink className='form-link cg-psw-link' to={CHANGE_PASSWORD_ROUTE}>Change password</NavLink>
                </div>
                <div className='sign-out-container'>
                    <button className='sign-out-btn' onClick={signOut}>
                        <SignOutIcon/>
                        Sign out
                    </button>
                </div>
                
            </div>
        </div>
    )
}

export default UserInfo;