import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_en from './translations/en.json';
import common_no from './translations/no.json';

const resources = {
    en: {
        translation: common_en
    },
    no: {
        translation: common_no
    },
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'no',
        // keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n;