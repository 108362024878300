import React from 'react'

function ArrowRight() {
    return (
        <svg className='arrow-right' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 11.5593L16.5005 12.1179L16.5098 11.0093L16 11.5593ZM11.6502 8.55005L15.4902 12.1094L16.5098 11.0093L12.6698 7.44995L11.6502 8.55005ZM15.4995 11.0007L11.6595 14.4414L12.6605 15.5586L16.5005 12.1179L15.4995 11.0007ZM7 12.3093H11.5V10.8093H7L7 12.3093ZM11.5 12.3093H16V10.8093H11.5V12.3093Z" fill="#001F40"/>
            <circle cx="12" cy="12" r="11.5" stroke="#001F40"/>
        </svg>
    )
}

export default ArrowRight
