export const RESET_PASSWORD_STAGE_SEND_CODE = 'sendCode'
export const RESET_PASSWORD_STAGE_RESET = 'reset'

const defaultState = {
    resetPasswordStage: RESET_PASSWORD_STAGE_SEND_CODE,
    isLoading: false,
    message: null,
    error: null
}

const ON_RESET_RECOVERY_FORMS = 'ON_RESET_RECOVERY_FORMS';

const ON_SEND_CODE_REQUEST = 'ON_SEND_CODE_REQUEST';
const ON_SEND_CODE_SUCCESS = 'ON_SEND_CODE_SUCCESS';
const ON_SEND_CODE_FAILURE = 'ON_SEND_CODE_FAILURE';

const ON_RESET_PASSWORD_REQUEST = 'ON_RESET_PASSWORD_REQUEST';
const ON_RESET_PASSWORD_SUCCESS = 'ON_RESET_PASSWORD_SUCCESS';
const ON_RESET_PASSWORD_FAILURE = 'ON_RESET_PASSWORD_FAILURE';



export const resetPasswordReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ON_RESET_RECOVERY_FORMS:
            return {...state, ...defaultState}
        case ON_SEND_CODE_REQUEST:
            return {...state, resetPasswordStage: RESET_PASSWORD_STAGE_SEND_CODE, isLoading: true, error: null, message: null}
        case ON_SEND_CODE_SUCCESS:
            return {...state, resetPasswordStage: RESET_PASSWORD_STAGE_RESET, isLoading: false, error: null, message: action.payload}
        case ON_SEND_CODE_FAILURE:
            return {...state, resetPasswordStage: RESET_PASSWORD_STAGE_SEND_CODE, isLoading: false, error: action.payload, message: null}

        case ON_RESET_PASSWORD_REQUEST:
            return {...state, resetPasswordStage: RESET_PASSWORD_STAGE_RESET, isLoading: true, error: null, message: null}
        case ON_RESET_PASSWORD_SUCCESS:
            return {...state, resetPasswordStage: RESET_PASSWORD_STAGE_RESET, isLoading: false, error: null, message: action.payload}
        case ON_RESET_PASSWORD_FAILURE:
            return {...state, resetPasswordStage: RESET_PASSWORD_STAGE_RESET, isLoading: false, error: action.payload, message: null}
        default:
            return state
    }
}

export const onResetRecoveryForms= () => ({type:ON_RESET_RECOVERY_FORMS})

export const onSendCodeRequest = (payload) => ({type:ON_SEND_CODE_REQUEST, payload})
export const onSendCodeSuccess = (payload) => ({type:ON_SEND_CODE_SUCCESS, payload})
export const onSendCodeFailure = (payload) => ({type:ON_SEND_CODE_FAILURE, payload})

export const onResetPasswordRequest = (payload) => ({type:ON_RESET_PASSWORD_REQUEST, payload})
export const onResetPasswordSuccess = (payload) => ({type:ON_RESET_PASSWORD_SUCCESS, payload})
export const onResetPasswordFailure = (payload) => ({type:ON_RESET_PASSWORD_FAILURE, payload})