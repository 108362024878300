import React from 'react'
import SignInForm from '../forms/sign-in-form'
import ForgotPasswordForm from './../forms/forgot-password-form';
import { useLocation } from 'react-router-dom';
import { SIGNIN_ROUTE } from './../../utils/constants';

import './pages.css';
import AppLogo from './../logo';
import FrontImage from '../icons/front-image';

function AuthPage() {

    const location = useLocation();
    const isLogin = location.pathname === SIGNIN_ROUTE;

    return (
        <div className='abra-page sign-in-page' style={{height: '100vh'}}>
            <section className='page-part page-filler'>
                <FrontImage/>
            </section>
            <section className='page-part page-main'>
                <div className='page-content'>
                    <AppLogo/>
                    <div className='content-card'>
                        {isLogin ? <SignInForm/> : <ForgotPasswordForm/>}
                    </div>
                </div>
            </section>
        </div>
    )
}
export default AuthPage
