import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom';
import { authRoutes, publicRoutes } from '../../routes';
import * as routeConsts from '../../utils/constants'
import RequireAuth from './Auth';

function AppRouter() {

    return (
            <Routes>
                {/* <Route path={'/sign-in'} element={<LoginPage/>} exact/> */}
                {authRoutes.map(({path, Component}) => {
                    return <Route key={path} path={path} element={<RequireAuth><Component/></RequireAuth>} exact/>
                })}
                {publicRoutes.map(({path, Component}) => {
                    return <Route key={path} path={path} element={<Component/>} exact/>
                })}
                <Route path="*" element={<Navigate to={routeConsts.INVITE_USER_ROUTE}/>} />
            </Routes>
    )
}

export default AppRouter