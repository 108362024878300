import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function LanguageSelector() {

    const onLanguageChange = (option) => {
        localStorage.setItem('lang', option.target.value);
        window.location.reload();
    }

    const lang = localStorage.getItem('lang') || 'no';

    return (
        <div className='language-selector'>
            {/* <select onChange={onLanguageChange} value={lang}>
                <option value='en'>English</option>
                <option value='no'>Norvegian</option>
            </select> */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                    value={lang}
                    onChange={onLanguageChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'no'}>Norwegian</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default LanguageSelector