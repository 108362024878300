import { useDispatch, useSelector } from 'react-redux';

import { IconButton, TextField } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { addCustomerValidator } from '../../utils/validators';
import SubmitButton from './submit-button';
import { onResetAddCustomerForm } from '../../store/addCustomerReducer';
import { tryAddCustomer } from '../../store/asyncActons';
import i18next from "i18next";
import InputMask from "react-input-mask";

function AddCustomerForm() {

    const {success, loading, error} = useSelector(state => state.addCustomer)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(onResetAddCustomerForm())
      }, [])

    const formik = useFormik({
        initialValues: {
            firstName:'',
            lastName: '',
            email: '',
            phone: '47',
            address: '',
            city: '',
            postalCode: '',
        },
        validate:(values) => addCustomerValidator(values),
        onSubmit: async (values, { setSubmitting }) => {
            console.log(values);    
            dispatch(tryAddCustomer('1', values))
        },
    });

    return (
        <div className='form-container'>
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <Collapse in={error !== null}>
                        <Alert
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(onResetAddCustomerForm());
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        severity={'error'}
                        >
                            {(error && !success) && (error.message || i18next.t('forms.errors.unknownError'))}
                        
                        </Alert>
                    </Collapse>
                </div>
                <div className='form-body' style={{display: 'block'}}>
                    <div>
                        <TextField 
                            id="firstName"
                            label={i18next.t('forms.addCustomer.firstNameLabel')}
                            name='firstName' 
                            variant="standard"
                            inputProps={{maxLength: 30}}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.firstName && formik.touched.firstName}
                            helperText={formik.errors.firstName && formik.touched.firstName && formik.errors.firstName}
                            />
                    </div>
                    <div>
                        <TextField 
                            id="lastName"
                            label={i18next.t('forms.addCustomer.lastNameLabel')}
                            name='lastName' 
                            variant="standard"
                            inputProps={{maxLength: 30}}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.lastName && formik.touched.lastName}
                            helperText={formik.errors.lastName && formik.touched.lastName && formik.errors.lastName}
                            />
                    </div>
                    <div>
                        <TextField 
                            id="email"
                            label={i18next.t('forms.addCustomer.emailLabel')}
                            name='email' 
                            variant="standard"
                            inputProps={{maxLength: 100}}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.email && formik.touched.email}
                            helperText={formik.errors.email && formik.touched.email && formik.errors.email}
                            />
                    </div>
                    <div style={{marginTop: '30px'}}>
                        <TextField 
                            id="address"
                            label={i18next.t('forms.addCustomer.addressLabel')}
                            name='address' 
                            variant="standard"
                            inputProps={{maxLength: 100}}
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.address && formik.touched.address}
                            helperText={formik.errors.address && formik.touched.address && formik.errors.address}
                            />
                    </div>
                    <div>
                        <TextField 
                            id="postalCode"
                            label={i18next.t('forms.addCustomer.postalCodeLabel')}
                            name='postalCode' 
                            variant="standard"
                            inputProps={{maxLength: 100}}
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.postalCode && formik.touched.postalCode}
                            helperText={formik.errors.postalCode && formik.touched.postalCode && formik.errors.postalCode}
                            />
                    </div>
                    <div>
                        <TextField 
                            id="city"
                            label={i18next.t('forms.addCustomer.cityLabel')}
                            name='city' 
                            variant="standard"
                            inputProps={{maxLength: 100}}
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.city && formik.touched.city}
                            helperText={formik.errors.city && formik.touched.city && formik.errors.city}
                            />
                    </div>
                    <div>
                        <InputMask
                            mask="(+99) 999 99 999 99999"
                            value={formik.values.phone}
                            disabled={false}
                            maskChar=" "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            {() => <TextField 
                            id="phone"
                            label={i18next.t('forms.addCustomer.phoneLabel')}
                            name='phone' 
                            variant="standard"
                            value={formik.values.phone}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            error={formik.errors.phone && formik.touched.phone}
                            helperText={formik.errors.phone && formik.touched.phone && formik.errors.phone}
                            />}
                        </InputMask>
                        
                    </div>
                    
                    <div style={{marginBottom: '20px'}}>
                        {<SubmitButton label={i18next.t('forms.addCustomer.submitButtonLabel')} disabled={!(formik.isValid && formik.dirty)} loading={loading}/>}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddCustomerForm
