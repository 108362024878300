import React from 'react'

function UserIcon() {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" className='navbar-icon user-icon' fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.7333 17.2049C18.7333 16.1384 18.0715 15.1859 17.0683 14.8236C15.5945 14.291 13.1638 13.6665 9.86667 13.6665C6.56953 13.6665 4.1388 14.291 2.66503 14.8236C1.66183 15.1859 1 16.1384 1 17.2049V19.9998H18.7333V17.2049Z" stroke="#808F9F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square"/>
            <path d="M9.86693 11.1333C7.41846 11.1333 5.43359 9.14847 5.43359 6.7V5.43333C5.43359 2.98487 7.41846 1 9.86693 1C12.3154 1 14.3003 2.98487 14.3003 5.43333V6.7C14.3003 9.14847 12.3154 11.1333 9.86693 11.1333Z" stroke="#808F9F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square"/>
        </svg>
    )
}

export default UserIcon
