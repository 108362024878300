import { legacy_createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { appReducer } from './appReducer';
import { authReducer } from './authReducer';
import { userReducer } from './userReducer';
import { resetPasswordReducer } from './resetPasswordReducer';
import { changePasswordReducer } from './changePasswordReducer';
import { addCustomerReducer } from './addCustomerReducer';
import { acceptInvitationReducer } from './acceptInvitationReducer';

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    passwordRecovery: resetPasswordReducer,
    changePassword: changePasswordReducer,
    addCustomer: addCustomerReducer,
    acceptInvitation: acceptInvitationReducer,
})

export const store = legacy_createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))