export const getUserInformation = `query getUserInfo {
    UserGetUser{
        id
        first_name
        last_name
        email
        age
        phone_number
        gender
        street
        street_2
        city
        post_code
        state
        country
    }
}`