import React from 'react'

function SubmitButton({
    label,
    disabled,
    loading,
    onClick = null,
}) {
    const onButtonClick = (e) => {
        if(onClick) {
            onClick(e)
        }
     }
    return (
        <button type='submit' className={`submit-btn ${disabled ? 'btn-disabled' : ''}`} onClick={onButtonClick}>
            {loading ? <div className="loader"></div> : label}
        </button>
    )
}

export default SubmitButton
