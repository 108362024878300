import React from 'react'

function AddCustomerIcon() {
    return (
        <svg className='navbar-icon add-customer-icon' width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4828 13.5236C10.8734 13.4758 10.2189 13.4482 9.51724 13.4482C5.86793 13.4482 3.45886 14.2115 2.16162 14.7868C1.45207 15.1019 1 15.8056 1 16.5819V20H11.4828" stroke="#808F9F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square"/>
            <path d="M4.93066 5.58621C4.93066 3.05331 6.98397 1 9.51687 1C12.0498 1 14.1031 3.05331 14.1031 5.58621C14.1031 8.1191 12.0498 10.8276 9.51687 10.8276C6.98397 10.8276 4.93066 8.1191 4.93066 5.58621Z" stroke="#808F9F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square"/>
            <path d="M17.3722 12.0833V19.4958" stroke="#808F9F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square"/>
            <path d="M13.666 15.7896H21.0784" stroke="#808F9F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square"/>
        </svg>
    )
}

export default AddCustomerIcon
