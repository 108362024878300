import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import { trySendResetCode } from '../../store/asyncActons';
import '../../styles/forms/forms.css'
import { emailValidator } from '../../utils/validators';
import { tryResetPassword } from './../../store/asyncActons';
import { onResetRecoveryForms, RESET_PASSWORD_STAGE_SEND_CODE } from './../../store/resetPasswordReducer';
import { SIGNIN_ROUTE } from './../../utils/constants';
import { NavLink } from 'react-router-dom';
import backImg from '../../assets/images/back-arrow.png';

function ForgotPasswordForm() {

    const {resetPasswordStage, isLoading, message, error} = useSelector(state=> state.passwordRecovery)
    const [email, setEmail] = useState('')
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(onResetRecoveryForms())
    
    }, [])

    
    // const title = 'Forgot your password?'
    const title = (() => {
        switch (resetPasswordStage) {
        case 'sendCode':
            return 'Forgot your password?'
        case 'resetPassword':
            return 'Reset Password'
        default:
            return 'Forgot your password?'
    }
    })();
    
    const SendCode = () => {

        const sendCodeForm = useFormik({
            initialValues: {
                email: '',
            },
            validate:(values) => emailValidator(values),
            onSubmit: async (values, { setSubmitting }) => {
                console.log(values);
                setEmail(values.email)
                dispatch(trySendResetCode(values.email))
            },
        });

        return (
            <form onSubmit={sendCodeForm.handleSubmit}>
                <div>
                    <TextField 
                        id="email"
                        label="Email"
                        name='email' 
                        variant="standard" 
                        value={sendCodeForm.values.email}
                        onChange={sendCodeForm.handleChange}
                        onBlur={sendCodeForm.handleBlur}
                        error={sendCodeForm.errors.email && sendCodeForm.touched.email}
                        helperText={sendCodeForm.errors.email && sendCodeForm.touched.email && sendCodeForm.errors.email}
                        />
                </div>
                <div className='form-error'>{error && error.message}</div>
                <div>
                    <button type='submit' className='submit-btn'>Send me an email to reset password</button>
                </div>
                    
            </form>
        )
    }

    const ResetPassword = () => {

        const resetPasswordForm = useFormik({
            initialValues: {
                code: '',
                password: '',
                confirmPassword: '',
            },
            validate:(values) => emailValidator(values),
            onSubmit: async (values, { setSubmitting }) => {
                console.log(values);    
                    dispatch(tryResetPassword(email, values.code, values.password))
            },
        });

        return (
            <form onSubmit={resetPasswordForm.handleSubmit}>
                <div>
                    <TextField 
                        id="code"
                        label="Reset password code"
                        name='code' 
                        variant="standard" 
                        value={resetPasswordForm.values.code}
                        onChange={resetPasswordForm.handleChange}
                        onBlur={resetPasswordForm.handleBlur}
                        error={resetPasswordForm.errors.code && resetPasswordForm.touched.code}
                        helperText={resetPasswordForm.errors.code && resetPasswordForm.touched.code && resetPasswordForm.errors.code}
                        />
                    <TextField 
                        id="password"
                        label="New password"
                        name='password' 
                        variant="standard" 
                        value={resetPasswordForm.values.password}
                        onChange={resetPasswordForm.handleChange}
                        onBlur={resetPasswordForm.handleBlur}
                        error={resetPasswordForm.errors.password && resetPasswordForm.touched.password}
                        helperText={resetPasswordForm.errors.password && resetPasswordForm.touched.password && resetPasswordForm.errors.password}
                        />
                    <TextField 
                        id="confirmPassword"
                        label="Confirm new password"
                        name='confirmPassword' 
                        variant="standard" 
                        value={resetPasswordForm.values.confirmPassword}
                        onChange={resetPasswordForm.handleChange}
                        onBlur={resetPasswordForm.handleBlur}
                        error={resetPasswordForm.errors.confirmPassword && resetPasswordForm.touched.confirmPassword}
                        helperText={resetPasswordForm.errors.confirmPassword && resetPasswordForm.touched.confirmPassword && resetPasswordForm.errors.confirmPassword}
                        />
                </div>
                <div className='form-error'>{error && error.message}</div>
                <div>
                    <button type='submit' className='submit-btn'>Reset password</button>
                </div>

            </form>
        )
    }


    return (
        <div className='forgot-password-form' style={{width: '320px', position:'relative'}}>
            <div className='form-title'>
                <h1>{title}</h1>
            </div>
            <NavLink className='back-link' to={SIGNIN_ROUTE}>
                <img src={backImg} alt="Back to Sign-in" />
            </NavLink>
            {/* <div className='back-link'>
                <img src={backImg} alt="Back to Sign-in" />
            </div> */}
            <div className='form-container'>
                {resetPasswordStage === RESET_PASSWORD_STAGE_SEND_CODE ? <SendCode/> : <ResetPassword/> }
            </div>
        </div>
    )
}

export default ForgotPasswordForm
