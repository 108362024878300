import React from 'react'

function InfoItem({
    label,
    value,
}) {
    return (
        <div className='info-item'>
            <div className='info-item-label'>{label}</div>
            <div className='info-item-value'>{value || '-'}</div>
        </div>
    )
}

export default InfoItem
