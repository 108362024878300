import i18next from "i18next";

export const emailValidator = (values) => {
    let errors = {};

    if (!values.email) {
        errors.email = i18next.t('forms.errors.emailRequiredError');
    }
    else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = i18next.t('forms.errors.emailInvalidError');
    }
    return errors;
}

export const signInValidator = (values) => {
    let errors = {};

    const emailerrors = emailValidator(values);

    errors = {...emailerrors};

    if (!values.password || values.password.trim().length === 0) {
        errors.password = 'Required.';
    }

    return errors;
}

export const resetPasswordValidator = (values) => {
    let errors = {};

    const emailerrors = emailValidator(values);

    errors = {...emailerrors};


    if (!values.code || values.code.trim().length === 0) {
        errors.code = 'Required.';
    }

    if (!values.password || values.password.trim().length === 0) {
        errors.password = 'Required.';
    }

    if (values.confirmPassword !== values.password) {
        errors.password = 'The password confirmation does not match!';
    }

    return errors;
}

export const changePasswordValidator = (values) => {
    let errors = {};


    if (!values.oldPassword || values.oldPassword.trim().length === 0) {
        errors.oldPassword = 'Required.';
    }

    if (!values.newPassword || values.newPassword.trim().length === 0) {
        errors.newPassword = 'Required.';
    }

    if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = 'The password confirmation does not match!';
    }

    return errors;
}

export const addCustomerValidator = (values) => {
    let errors = {}

    const emailerrors = emailValidator(values);

    errors = {...emailerrors};

    if (!values.firstName || values.firstName.trim().length === 0) {
        errors.firstName = i18next.t('forms.errors.firstNameRequiredError');
    }

    if (!values.lastName || values.lastName.trim().length === 0) {
        errors.lastName = i18next.t('forms.errors.lastNameRequiredError');
    }

    if (!values.address || values.address.trim().length === 0) {
        errors.address = i18next.t('forms.errors.addressRequiredError');
    }

    if (!values.postalCode || values.postalCode.trim().length === 0) {
        errors.postalCode = i18next.t('forms.errors.postalCodeRequiredError');
    }

    if (!values.city || values.city.trim().length === 0) {
        errors.city = i18next.t('forms.errors.cityRequiredError');
    }

    const phone = values.phone.replace(/\D/g,'');

    if (!phone || phone.length === 0) {
        errors.phone = i18next.t('forms.errors.phoneRequiredError');
    }
    else if(phone.length < 10){
        errors.phone = i18next.t('forms.errors.phoneInvalidError');
    }

    return errors;
}