import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import NavBar from '../navbar';
import './pages.css';
import UserInfo from '../user-info';
import { fetchUserInfo } from '../../store/asyncActons';

function UserPage() {

    const {isUserLoading, userData, error} = useSelector(state=>state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchUserInfo())
    }, [])

    return (
        <div className='abra-page user-page'>
            <NavBar/>
            <div className='page-content internal-content'>
                <h1>My user</h1>
                {isUserLoading && <div>Loading...</div>}
                {!isUserLoading && error && <div>Could not fetch user data.<br/>Please, try again later</div>}
                {!isUserLoading && !error && <UserInfo user={userData}/>}
                
            </div>

        </div>
    )
}

export default UserPage
