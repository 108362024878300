import AddCustomerPage from './components/pages/AddCustomerPage';
import AuthPage from './components/pages/AuthPage';
import ChangePasswordPage from './components/pages/ChangePasswordPage';
import UserPage from './components/pages/UserPage';
import * as routeConsts from './utils/constants';
import AcceptInvitationPage from './components/pages/AcceptInvitationPage';

export const authRoutes = [
    {
        path: routeConsts.USER_INFO_ROUTE,
        Component: UserPage
    },
    {
        path: routeConsts.CHANGE_PASSWORD_ROUTE,
        Component: ChangePasswordPage
    },
]

export const publicRoutes = [
    {
        path: routeConsts.SIGNIN_ROUTE,
        Component: AuthPage
    },
    {
        path: routeConsts.FORGOT_PASSWORD_ROUTE,
        Component: AuthPage
    },
    {
        path: routeConsts.INVITE_USER_ROUTE,
        Component: AddCustomerPage
    },
    {
        path: routeConsts.ACCEPT_INVITATION_ROUTE,
        Component: AcceptInvitationPage
    },
    // {
    //     path: routeConsts.DEVICE_ROUTE +'/:id',
    //     Component: DevicePage
    // },
]