import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchUserInfo } from '../../store/asyncActons'
import ChangePasswordForm from '../forms/change-password-form'
import NavBar from '../navbar'

function ChangePasswordPage() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchUserInfo())
    }, [])

    return (
        <div className='abra-page change-password-page'>
            <NavBar/>
            <div className='page-content internal-content'>
                <h1>Change Password</h1>
                <div className='content-card'>
                    <ChangePasswordForm/>
                </div>

            </div>

        </div>
    )
}

export default ChangePasswordPage
