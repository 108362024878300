import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app';
import { Provider } from 'react-redux';
import { store } from './store';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/theme';
import './i18n';
import config from './config/config';
import i18next from 'i18next';
import { datadogLogs } from '@datadog/browser-logs';

const lang = localStorage.getItem('lang') || 'no';
i18next.changeLanguage(lang)

datadogLogs.init({
  clientToken: config.datadogClientToken,
  site: config.datadogSite,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'partnerportal',
  env: config.appEnvironment
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>
    </Provider>
);
