export const getPhoneWithFormat = (phone) => {
    
    phone = phone.replace(/\D/g,'');

    if(phone.length < 10)
     throw new Error('Invalid phone number')

    const countryCode =  '+' + phone.substring(0,2);
    const nationalNumber = phone.substring(2);

    return [countryCode, nationalNumber];

}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }