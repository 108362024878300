const defaultState = {
    success: false,
    loading: false,
    error: null,
}

const ON_RESET_CHANGE_PASSWORD_FORM = 'ON_RESET_CHANGE_PASSWORD_FORM';

const On_USER_CHANGE_PASSWORD_REQUEST = 'On_USER_CHANGE_PASSWORD_REQUEST';
const On_USER_CHANGE_PASSWORD_FAILURE = 'On_USER_CHANGE_PASSWORD_FAILURE';
const On_USER_PASSWORD_CHANGED = 'On_USER_PASSWORD_CHANGED';


export const changePasswordReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ON_RESET_CHANGE_PASSWORD_FORM:
            return {...state, success: false, loading: false, error: null}

        case On_USER_CHANGE_PASSWORD_REQUEST:
            return {...state, success: false, loading: true, error: null}
        case On_USER_CHANGE_PASSWORD_FAILURE:
            return {...state, success: false, loading: false, error: action.payload }
        case On_USER_PASSWORD_CHANGED:
            return {...state, success: true, loading: false, error: null}

        default:
            return state
    }
}

export const onResetChangePasswordForm= (payload) => ({ type: ON_RESET_CHANGE_PASSWORD_FORM, payload });

export const onUserChangePasswordRequest = () => ({type:On_USER_CHANGE_PASSWORD_REQUEST})
export const onUserChangePasswordFailure = (payload) => ({type:On_USER_CHANGE_PASSWORD_FAILURE, payload})
export const onUserPasswordChanged = () => ({type:On_USER_PASSWORD_CHANGED})