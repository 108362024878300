import React, {useState, useEffect} from 'react'
import { useFormik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

import { signInValidator } from '../../utils/validators';
import { tryUserLogin } from '../../store/asyncActons';
import { FORGOT_PASSWORD_ROUTE } from '../../utils/constants';

import '../../styles/forms/forms.css'
import { onResetAuthForm } from '../../store/authReducer';
import SubmitButton from './submit-button';

function SignInForm() {

    const [showPassword, setShowPassword] = useState(false);
    const {onLoginLoading, error} = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(onResetAuthForm())
    
    }, [])
    
    const formik = useFormik({
        initialValues: {
            email:'',
            password: '',
        },
        validate:(values) => signInValidator(values),
        onSubmit: async (values, { setSubmitting }) => {
            console.log(values);    
            dispatch(tryUserLogin(values.email, values.password, navigate))
        },
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <div className='sign-in-form'>
            <div className='form-title'>
                <h1>Sign In</h1>
            </div>
            <div className='form-container'>
                <form onSubmit={formik.handleSubmit}>
                    <div>
                    <Collapse in={error !== null}>
                        <Alert
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(onResetAuthForm())
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        severity={'error'}
                        >
                            {error && error.message}
                        </Alert>
                    </Collapse>
                    </div>
                    <div>
                        <TextField 
                            id="email"
                            label="Email"
                            name='email' 
                            variant="standard"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.email && formik.touched.email}
                            helperText={formik.errors.email && formik.touched.email && formik.errors.email}
                            />
                    </div>
                    <div>
                        <FormControl 
                            sx={{ marginTop: '10px', width: '100%' }}
                            variant="standard"
                            error={formik.errors.password && formik.touched.password}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            {formik.errors.password && formik.touched.password && <FormHelperText>{formik.errors.password}</FormHelperText>}
                        </FormControl>
                    </div>
                    <div style={{textAlign:'right'}}>
                        <NavLink className='form-link fg-psw-link' to={FORGOT_PASSWORD_ROUTE}>Forgot password?</NavLink>
                        {/* <a href="#" className='form-link fg-psw-link'>Forgot password?</a> */}
                    </div>
                    {/* <div className='form-error'>{error && error.message}</div> */}
                    <div>
                        <SubmitButton label="Sign in" disabled={!(formik.isValid && formik.dirty)} loading={onLoginLoading}/>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}

export default SignInForm
