import React from 'react'

function SignOutIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='sign-out-img'>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.6065 5.3553C13.9971 4.96483 14.6302 4.96491 15.0207 5.35549L18.9572 9.29299C19.3476 9.68349 19.3476 10.3165 18.9572 10.707L15.0207 14.6445C14.6302 15.0351 13.9971 15.0352 13.6065 14.6447C13.2159 14.2542 13.2158 13.6211 13.6063 13.2305L16.8359 10L13.6063 6.76951C13.2158 6.37894 13.2159 5.74577 13.6065 5.3553Z" fill="#FF8179"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.75 10C6.75 9.44771 7.19772 9 7.75 9H18.2472C18.7995 9 19.2472 9.44771 19.2472 10C19.2472 10.5523 18.7995 11 18.2472 11H7.75C7.19772 11 6.75 10.5523 6.75 10Z" fill="#FF8179"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.75 2.75H7.75C8.30228 2.75 8.75 2.30228 8.75 1.75C8.75 1.19772 8.30228 0.75 7.75 0.75H2.5C2.03587 0.75 1.59075 0.934375 1.26256 1.26256C0.934375 1.59075 0.75 2.03587 0.75 2.5V17.5C0.75 17.9641 0.934374 18.4092 1.26256 18.7374C1.59075 19.0656 2.03587 19.25 2.5 19.25H7.75C8.30228 19.25 8.75 18.8023 8.75 18.25C8.75 17.6977 8.30228 17.25 7.75 17.25H2.75V2.75Z" fill="#FF8179"/>
        </svg>
    )
}

export default SignOutIcon
