import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { CHANGE_PASSWORD_ROUTE, INVITE_USER_ROUTE, USER_INFO_ROUTE } from '../../utils/constants';
import AddCustomerIcon from '../icons/add-customer-icon';
import UserIcon from '../icons/user-icon';
import NavLogo from '../logo/nav-logo';
import defaultUserImage from '../../assets/images/default-user-image.png';
import './navbar.css';

function NavBar() {

    const {isUserLoading, userData, error} = useSelector(state=>state.user)
    const location = useLocation();

    const getInitials = (userData) => {
        let firstLetter = 'A';
        let secondLetter = 'N';

        if(userData.first_name && userData.first_name.length > 0){
            firstLetter = userData.first_name[0].toUpperCase();
        }
        if(userData.last_name && userData.last_name.length > 0){
            secondLetter = userData.last_name[0].toUpperCase();
        }

        return firstLetter + secondLetter;
    }

    return (
        <aside className='navbar'>
            <div className='navbar-header'>
                <NavLogo/>
            </div>
            <div className='navbar-avatar-container'>
                <div className='navbar-avatar'>
                    <img src={defaultUserImage} alt="user avatar"/>
                </div>
            </div>
            <div className='navbar-list'>
                <NavLink
                    // className='navbar-item'
                    to={USER_INFO_ROUTE}
                    className={({ isActive }) =>
                        isActive || location.pathname.includes(CHANGE_PASSWORD_ROUTE) ? 'navbar-item active' : 'navbar-item'
                    }
                    >
                    <UserIcon/>
                    <div className='navbar-item-label'>My User</div>
                </NavLink>
                <NavLink className='navbar-item' to={INVITE_USER_ROUTE}>
                    <AddCustomerIcon/>
                    <div className='navbar-item-label'>Add Customer</div>
                </NavLink>
            </div>

            {!isUserLoading && !error && userData && <>
                    <div className='navbar-item navbar-user-info'>
                        <div className='user-name-icon'>{getInitials(userData)}</div>
                        <div className='navbar-item-label'>{`${userData.first_name} ${userData.last_name}`}</div>
                    </div>
                </>}
            
        </aside>
    )
}

export default NavBar
