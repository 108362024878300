import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import LanguageSelector from '../language-selector';
import FrontImage from '../icons/front-image';
import AppLogo from '../logo';
import i18next from "i18next";
import { Trans } from 'react-i18next';
import SubmitButton from '../forms/submit-button';
import ArrowRight from '../icons/arrow-right';
import { useDispatch, useSelector } from 'react-redux';
import { onAcceptInvitationFailure, onResetAcceptInvitationForm } from '../../store/acceptInvitationReducer';
import { tryAcceptInvitation } from './../../store/asyncActons';

import { IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Amplify from 'aws-amplify';
import config from '../../config/config';
import { capitalizeFirstLetter } from '../../utils/functions';
import SuccessIcon from '../icons/success-icon';

function AcceptInvitationPage() {

    Amplify.configure({
        Auth: {
            mandatorySignIn: false,
            // REQUIRED - Amazon Cognito Identity Pool ID
            userPoolId: config.userPoolId,
            // REQUIRED - Amazon Cognito Region
            region: config.region,
            // OPTIONAL - Amazon Cognito User Pool ID
            identityPoolId: config.identityPoolId,
            // OPTIONAL - Amazon Cognito Web Client ID
            userPoolWebClientId: config.userPoolWebClientId,
        },
        aws_appsync_graphqlEndpoint: config.graphqlEndpoint,
        aws_appsync_region: config.appsyncRegion,
        aws_appsync_authenticationType: "AWS_IAM"
        });

    const [token, setToken] = useState(null);
    const [invitationId, setInvitationId] = useState(null)
    const [userName, setUserName] = useState('User')


    const {success, loading, error} = useSelector(state => state.acceptInvitation)
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();

    useEffect(() => {

        const userFirstNameFromUrl = searchParams.get('first_name');
        const invitationIdFromUrl = searchParams.get('id');
        const tokenFromUrl = searchParams.get('token');

        if(userFirstNameFromUrl){
            setUserName(userFirstNameFromUrl)
        }

        if(tokenFromUrl){
            setToken(tokenFromUrl)
        }

        if(invitationIdFromUrl){
            setInvitationId(invitationIdFromUrl)
        }

        dispatch(onResetAcceptInvitationForm())

        if(!invitationIdFromUrl || !tokenFromUrl){
            dispatch(onAcceptInvitationFailure(new Error('Invalid token or invitation ID')))
        }

    }, [])
    
    const onAcceptButtonClick = () => {
        dispatch(tryAcceptInvitation(invitationId, token));
    }

    return (
        <div className='abra-page accept-terms-page' style={{height: '100vh'}}>
            <section className='page-part page-filler'>
                <FrontImage/>
            </section>
            <section className='page-part page-main'>
                <div className='page-content'>
                    <div className='language-selector-container'>
                        <LanguageSelector/>
                    </div>
                    <AppLogo/>
                    <div className='content-card wide-card'>
                        <div className='accept-message'>
                            {success && <div style={{textAlign: 'center', marginBottom: '20px'}}>
                                <SuccessIcon/>
                            </div>}
                            <div className='accept-message-title'>{!success ? i18next.t('pages.AcceptTerms.acceptMessageTitle', {name: capitalizeFirstLetter(userName)}): i18next.t('pages.AcceptTerms.acceptSuccessTitle')}</div>
                            <div className='accept-message-text'>{!success ? i18next.t('pages.AcceptTerms.acceptMessageText') : i18next.t('pages.AcceptTerms.successMessageText')}</div>
                        </div>
                        <div className='terms-link'>
                            <Trans i18nKey="pages.AcceptTerms.termsLink">See <a target="_blank" href="https://go.abralife.com/3fetRF6">terms here</a></Trans>
                        </div>
                        <div>
                            <Collapse in={error !== null}>
                                <Alert
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        dispatch(onResetAcceptInvitationForm());
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2, marginTop: '20px' }}
                                severity={'error'}
                                >
                                    {(error && !success) && (error.message || i18next.t('forms.errors.unknownError'))}
                                
                                </Alert>
                            </Collapse>
                        </div>
                        <div className='accept-button-container' style={{display: 'flex'}}>
                            {!success ? 
                                <SubmitButton label={i18next.t('pages.AcceptTerms.submitButtonLabel')} disabled={!invitationId || !token} loading={loading} onClick={onAcceptButtonClick}/>
                                : <a target='_blank' rel='noreferrer' href='https://abralife.no/landing/dnb-privat' className='submit-btn'>{i18next.t('pages.AcceptTerms.readMoreLinkLabel')}</a>
                            }
                            
                        </div>
                        <div className='read-more-container' style={{display: !success ? 'block' : 'none'}}>
                            <a className='read-more-link' target='_blank' rel='noreferrer' href='https://abralife.no/landing/dnb-privat'>
                                <ArrowRight/><span>{i18next.t('pages.AcceptTerms.readMoreLinkLabel')}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AcceptInvitationPage
