const defaultState = {
    isLoading: true
  }
  
  const SET_APPLICATION_LOADING = 'SET_APPLICATION_LOADING'

  export const appReducer = (state = defaultState, action) => {
    switch (action.type) {
      case SET_APPLICATION_LOADING:
        return {...state, isLoading: action.payload}
      default:
        return state
    }
  }

  export const setAppIsLoading = (payload) => ({type: SET_APPLICATION_LOADING, payload})