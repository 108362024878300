import React from 'react'
import {Navigate, useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as routeConsts from '../../utils/constants'

export default function RequireAuth({ children }) {


    const isAuth = useSelector(state => state.auth.isAuth);
    let location = useLocation();
    console.log('auth ckecking: ', isAuth);
    if (!isAuth) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to={routeConsts.SIGNIN_ROUTE} state={{ from: location }} replace />;
    }
  
    return children;
  }