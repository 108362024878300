const defaultState = {
    success: false,
    loading: false,
    error: null
}

const ON_RESET_ADD_CUSTOMER_FORM = 'ON_RESET_ADD_CUSTOMER_FORM';


const ON_ADD_CUSTOMER_REQUEST = 'ON_ADD_CUSTOMER_REQUEST';
const ON_ADD_CUSTOMER_FAILURE = 'ON_ADD_CUSTOMER_FAILURE';
const ON_CUSTOMER_ADDED = 'ON_CUSTOMER_ADDED';

export const addCustomerReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ON_RESET_ADD_CUSTOMER_FORM:
            return {...state, ...defaultState}

        case ON_ADD_CUSTOMER_REQUEST:
            return {...state, success: false, loading: true, error: null}
        case ON_ADD_CUSTOMER_FAILURE:
            return {...state, success: false, loading: false, error: action.payload }
        case ON_CUSTOMER_ADDED:
            return {...state, success: true, loading: false, error: null}
        default:
            return state
    }
}

export const onResetAddCustomerForm= (payload) => ({ type: ON_RESET_ADD_CUSTOMER_FORM, payload });

export const onAddCustomerRequest = () => ({type:ON_ADD_CUSTOMER_REQUEST})
export const onAddCustomerFailure = (payload) => ({type:ON_ADD_CUSTOMER_FAILURE, payload})
export const onCustomerAdded = () => ({type:ON_CUSTOMER_ADDED})